import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MarkdownIt from "markdown-it";
import mdHighlightjs from "markdown-it-highlightjs";
import markdownItTaskLists from "markdown-it-task-lists";

const MarkdownAnswers = ({ markdownData }) => {
  const md = new MarkdownIt({
    html: true, // Enable HTML tags in source
    xhtmlOut: true, // Use '/' to close single tags (<br />)
    breaks: true, // Convert '\n' in paragraphs into <br>
    langPrefix: "language-", // CSS language prefix for fenced blocks
    linkify: true, // autoconvert URL-like text to links
    typographer: true, // Enable smartypants and other sweet transforms
  })
    .use(mdHighlightjs) // for code syntax highlighting
    .use(markdownItTaskLists, { label: true });

  const markdownHtml = md.render(markdownData);
  const classes = useStyles();

  return (
    <Box
      className={classes.chatBox}
      style={{
        backgroundColor: "#F6F6F6",
        borderRadius: "8px",
        padding: "20px",
      }}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#15314E",
        }}
        dangerouslySetInnerHTML={{ __html: markdownHtml }}
      />
    </Box>
  );
};

export default MarkdownAnswers;

// --------------- stylings ---------------

const useStyles = makeStyles({
  chatBox: {
    // <table>
    "& table": {
      margin: "20px 0px",
      width: "100%",
      border: "1px solid #A1BFDF",
      borderRadius: "8px",
      marginBottom: "20px",
    },
    "& th": {
      backgroundColor: "#EBF1F8",
      borderBottom: "1px solid #A1BFDF",
      padding: "8px",
      textAlign: "left",
    },
    "& tr, & td": {
      padding: "8px",
      textAlign: "left",
      backgroundColor: "#FEFEFE",
    },

    // <code>
    "& pre": {
      margin: "20px 0px",
    },

    // <ul> and <ol> list styling
    "& ul, & ol": {
      margin: "16px 0", // Add margin between list items
      paddingLeft: "20px", // Add left padding to indent the list items
    },

    "& li": {
      marginBottom: "8px", // Add spacing between list items
      lineHeight: "1.6", // Improve readability by increasing line height
    },

    // vertical spacing for headings
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginTop: "24px",
      marginBottom: "16px",
      lineHeight: "1.3",
    },

    // all elements
    "& *": {
      userSelect: "text",
    },
  },
});
