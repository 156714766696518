import { Box, Divider } from "@material-ui/core";

import { MoreActionButtons } from "../esgGptCompanyProfile/EsgGptCompanyRight";
import MarkdownAnswers from "./AnswersComps/MarkdownAnswers";
import SourcesBox from "./AnswersComps/SourcesBox";
import FollowUpQues from "./AnswersComps/FollowUpQues";
import ChartAnswer from "./AnswersComps/ChartAnswers";

export function ChatAnswers({ answer, chatId }) {
  return (
    <Box
      style={{
        width: "100%",
        marginLeft: 10,
        marginRight: 10,
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      {/* Answers box */}
      {/* markdown answers */}
      <MarkdownAnswers
        markdownData={answer?.data || "Answer could not be generated"}
      />

      {/* charts */}
      {answer?.chart_props && <ChartAnswer chartProps={answer?.chart_props} />}

      {/* sources */}
      {Array.isArray(answer?.source) && answer.source.length > 0 && (
        <SourcesBox sourceData={answer?.source} />
      )}

      {/* follow-up links */}
      {Array.isArray(answer?.prompts) && answer?.prompts?.length > 0 && (
        <FollowUpQues promptData={answer?.prompts} />
      )}

      {/* Action buttons */}
      <Divider variant="middle" />
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <MoreActionButtons chatId={chatId} answer={answer?.data || answer} />
      </Box>
    </Box>
  );
}

// --------- temp data ---------
