import io from "socket.io-client";
import UNIVERSAL from "./config";

let socket;

// connecting to socket io server from backend
const connectSocket = (token) => {
  socket = io(new URL(UNIVERSAL.BASEURL).origin, {
    query: `token=${token}`,
  });
};

export { socket, connectSocket };
