import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { Chart } from "react-google-charts";

const ChartAnswer = ({ chartProps }) => {
  //   console.log(chartProps);

  if (!chartProps?.content) {
    return null;
  }

  let updatedChartProps;
  try {
    // Evaluate the content safely with error handling
    const chartConfStr = JSON.stringify(
      // eslint-disable-next-line no-eval
      eval(
        "(" +
          chartProps?.content?.replaceAll("```", "").replaceAll("jsx", "") +
          ")"
      )
    );

    updatedChartProps = JSON.parse(chartConfStr);
  } catch (error) {
    console.error("Error parsing or evaluating chart configuration:", error);
    return <p>Error loading chart data</p>; // You can display a user-friendly message here
  }

  if (updatedChartProps?.data?.[0]?.[0] === "No Data Available") {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Chart
          chartType={updatedChartProps.chartType}
          data={updatedChartProps?.data}
          options={updatedChartProps.options}
          width="100%"
          height="500px"
          legendToggle
        />
      </CardContent>
    </Card>
  );
};

export default ChartAnswer;

/* "{
  chartType: "BarChart",
  data: [
    ["Company Name and Year", "Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress P Y1", { role: "style" }, { role: "tooltip", p: { html: true } }, "Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress1", { role: "style" }, { role: "tooltip", p: { html: true } }, "Total Volume Of Water Consumption", { role: "style" }, { role: "tooltip", p: { html: true } }],
    ["Tata Consultancy Services Limited (01-04-2021 To 31-03-2022)", 520733, "color: #1f77b4", "<div><strong>Question:</strong> Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress P Y1<br/><strong>Answer:</strong> 520733 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>", 0, "color: #ff7f0e", "<div><strong>Question:</strong> Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress1<br/><strong>Answer:</strong> 0 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>", 1319696, "color: #2ca02c", "<div><strong>Question:</strong> Total Volume Of Water Consumption<br/><strong>Answer:</strong> 1319696 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>"],
    ["Tata Consultancy Services Limited (01-04-2022 To 31-03-2023)", 0, "color: #1f77b4", "<div><strong>Question:</strong> Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress P Y1<br/><strong>Answer:</strong> 0 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>", 526730, "color: #ff7f0e", "<div><strong>Question:</strong> Total Volume Of Water Consumption Per Area Facility Or Plant Located In Areas Of Water Stress1<br/><strong>Answer:</strong> 526730 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>", 2082781, "color: #2ca02c", "<div><strong>Question:</strong> Total Volume Of Water Consumption<br/><strong>Answer:</strong> 2082781 Kiloliters<br/><strong>Company:</strong> Tata Consultancy Services Limited<br/><strong>Units:</strong> Kiloliters</div>"]
  ],
  options: {
    width: "100%",
    height: "100%",
    chartArea: { width: "70%", height: "70%" },
    legend: { position: "bottom" },
    tooltip: { isHtml: true },
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c"]
  }
}" */
