import React, { useEffect, useRef, useState } from "react";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MoreActionButtons } from "../esgGptCompanyProfile/EsgGptCompanyRight";
import { HeadingCard } from "../esgGptCompanyProfile/EsgGptCompany/EsgGptCompanyInfo";
import arrowUp from "../../../../images/arrow-up-circle.svg";
import avatar from "../../../../images/EsgWiseIconNew.png";

import { useDispatch, useSelector } from "react-redux";
import useChat from "./useChat";
import Spinner from "../../../loader/spinner";
import UserChatAnswerLoading from "./genericChatComps/AnswerLoading/UserChatAnswerLoading";
import { ChatAnswers } from "../CommonComps/ChatAnswers";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export function UserChatQuestion({ question, companyName }) {
  // const { chatQuestion } = useSelector((state) => state.esgGptReducer);
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
      <Avatar
        style={{
          backgroundColor: "white",
          width: "35px",
          color: "#3374B9",
          fontSize: "16px",
        }}
      >
        {companyName ? companyName.slice(0, 2) : "Mm"}
      </Avatar>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 500,
          color: "#15314E",
          padding: "10px 15px",
          userSelect: "text",
        }}
      >
        {question?.question || question}
      </Typography>
      {/* <IconButton
        style={{ color: "#3374B9", marginLeft: "auto" }}
        aria-label="add to shopping cart"
      >
        <EditOutlinedIcon style={{ color: "#3374B9", cursor: "pointer" }} />
      </IconButton> */}
    </div>
  );
}

export function UserChatAnswerLayout({
  isLoading,
  hideAvatar = false,
  children,
}) {
  return (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        padding: "5px 5px 10px 10px",
        marginLeft: hideAvatar && "80px",
      }}
    >
      {!hideAvatar && (
        <>
          <Avatar
            style={{
              backgroundColor: "white",
              width: "35px",
              color: "#3374B9",
              fontSize: "16px",
            }}
            src={avatar}
          />

          {/* <img
            src={avatar}
            alt="compantLogo"
            style={{
              alignSelf: "flex-start",
              // marginRight: "10px",
              width: "65px",
              height: "40px",
              marginLeft: "-10px",
            }}
          /> */}
          {/* // <Avatar
        // style={{
        //   alignSelf: isLoading ? "flex-start" : "center",
        //   marginRight: "10px",
        // }}
        // >
        //   IG
        // </Avatar></div> */}
        </>
      )}
      {children}
    </div>
  );
}

export function UserChatAnswer({ children }) {
  return (
    <div
      style={{ backgroundColor: "#F6F6F6", borderRadius: "8px", width: "100%" }}
    >
      {children}
    </div>
  );
}

// export function FollowUpQuestion({ question, handleFollowUpQuestion }) {
//   return (
//     <div
//       onClick={() => handleFollowUpQuestion(question)}
//       style={{ width: "fit-content", padding: "10px", cursor: "pointer" }}
//     >
//       <HeadingCard primaryHead={question} justifyCenter={true}>
//         <img src={arrowUp} style={{ marginLeft: "5px" }} alt="arrow icon" />
//       </HeadingCard>
//     </div>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    width: "100%",
    overflow: "scroll",
    marginBottom: "4%",
    // border:"solid",
    backgroundColor: theme.palette.background.paper,
    padding: "1px",
  },
  table: {
    minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    // border:"1px solid"
  },
  tableCell: {
    padding: "15px",
  },
  titleRow: {
    // border:"solid",
    // backgroundColor: "#F6F6F6",
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    padding: "19px 20px",
    // border:"solid",
    // textAlign:"center"
  },

  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },

  suggestion: {
    borderRadius: "80px",
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",
    marginTop: "10px",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  sourceTypo: {
    backgroundColor: theme.palette.background.contentBG,
    color: theme.palette.primary.main,
    border: "1px solid grey",
    borderRadius: "80px",
    padding: "4px 8px",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

export function FollowUpQuestion({ question, handleFollowUpQuestion }) {
  const classes = useStyles();
  return (
    <div
      style={{
        borderRadius: "80px",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        cursor: "pointer",
        marginTop: "10px",
      }}
      className={classes.suggestion}
      // onClick={() => handleFollowUpQuestion(question)}
    >
      <img
        src={arrowUp}
        style={{ marginLeft: "5px", height: "40%" }}
        alt="arrow icon"
      />
      <Typography
        // className={classes.typoColorSecondary}
        style={{
          fontSize: "13px",
          // fontWeight: secondaryHead ? 400 : 700,
          // color: "#242424",
          padding: "2px 6px 2px 3px",
          // lineHeight: "20px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontFamily: "Poppins",
        }}
      >
        {question?.question || question}
      </Typography>
    </div>
  );
}

function EsgGptChatMessageLayout({ companyName = "", setPlanExpired }) {
  const { handleChats } = useChat();
  const { chats } = useSelector((state) => state.esgGptReducer);
  const chat_tokens = JSON.parse(localStorage.getItem("chat_tokens"));
  const is_premium = JSON.parse(localStorage.getItem("is_premium"));
  const { mainDrawer } = useSelector((state) => state.esgGptReducer);

  const [dummyChat, setChats] = React.useState([0]);
  const [loading, setLoading] = React.useState(false);
  const [responseExpand, setResponseExpand] = React.useState(true);

  const [isVisible, setIsVisible] = useState(false);

  const [isVisibleTop, setIsVisibleTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // const containerDiv = document.getElementById('scrollContainer');
      // Show button if scroll position is not at the top or bottom
      if (
        scrollRef.current.scrollTop + scrollRef.current.clientHeight ===
        scrollRef.current.scrollHeight
      ) {
        setIsVisibleTop(true);
      } else {
        setIsVisibleTop(false);
      }
    };

    // Add scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on component mount

  const scrollToTop = () => {
    // const containerDiv = document.getElementById('scrollContainer');
    // scrollRef.current.scrollTop = 0;
    // const containerDiv =  scrollRef.current;
    // containerDiv.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });

    const containerDiv = scrollRef.current;
    const scrollStep = -containerDiv.scrollTop / 35;
    const scrollInterval = setInterval(() => {
      if (containerDiv.scrollTop !== 0) {
        containerDiv.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };
  const history = useHistory();
  const { location } = history;

  const isCompanyBased = location.pathname === "/esg_gpt/chat" ? false : true;

  const scrollRef = useRef(null);

  const handleScroll = () => {
    // Show button if scroll position is below 100px from the top
    // if (scrollRef.current?.scrollTop > 100) {
    if (
      scrollRef.current?.scrollTop <
      scrollRef.current?.scrollHeight - scrollRef.current?.clientHeight - 10
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    // Add scroll event listener when component mounts
    // console.log("run effect", scrollRef.current);
    scrollRef.current?.addEventListener("scroll", handleScroll);
    // Remove scroll event listener when component unmounts
    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef?.current]); // Empty dependency array ensures that the effect runs only once on component mount

  // console.log("loading", loading);
  React.useEffect(
    function () {
      const timeout = setTimeout(function () {
        setChats((preValue) => preValue.map((v) => 1));
        // setResponseExpand(false);
      }, 7000);

      return function () {
        clearTimeout(timeout);
      };
    },
    [loading]
  );

  const handleFollowUpQuestion = (question) => {
    if (chat_tokens > 0 || is_premium) {
      setLoading((preValue) => !preValue);
      setResponseExpand(true);
      setChats((preValue) => [...preValue, 0]);
      handleChats(question, companyName);
    } else {
      setPlanExpired(true);
    }
  };

  const handleExpandResponseDetail = () => {
    setResponseExpand((preValue) => !preValue);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chats.length]);

  const followUpQuestions = [
    `${companyName} sustainability efforts`,
    `${companyName} sustainability practices`,
    `${companyName} sustainability sourcing`,
  ];

  useEffect(() => {
    chats.length !== 0 && scrollToBottom();
  }, [chats, responseExpand]);

  // console.log("chats", chats);
  // const dispatch = useDispatch()
  return (
    <>
      <div
        style={{
          height: "88%",
          width: "100%",
          overflow: "scroll",
          // border: "solid red",
          // marginBottom: "1%",
        }}
        ref={scrollRef}

        // onScroll={() => handleScroll()}
      >
        <div>
          {chats.map((chat, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: mainDrawer ? "80vw" : "95vw",
              }}
              key={index}
            >
              {/*  -------- this section contains chat questions --------  */}
              <UserChatQuestion
                companyName={companyName}
                question={chat?.question.question || chat?.question}
              />
              <UserChatAnswerLayout isLoading={chat?.isLoading}>
                {/*  -------- this section contains chat answers --------  */}
                {chat?.isLoading ? (
                  //  -------- this section contains chat loading component --------
                  <UserChatAnswerLoading
                    chat={chat}
                    handleExpandMore={handleExpandResponseDetail}
                    responseExpand={responseExpand}
                  />
                ) : chat?.answer ? (
                  <ChatAnswers answer={chat?.answer} chatId={chat?._id} />
                ) : (
                  !chat?.isLoading &&
                  !chat?.answer && (
                    <Box
                      style={{
                        width: "100%",
                        marginLeft: 10,
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                      }}
                    >
                      <Box
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#15314E",
                          }}
                        >
                          Unable to generate a response for your message. Please
                          try again.
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </UserChatAnswerLayout>

              {/* answer loading expanded section */}
              {/* {responseExpand && chat?.isLoading && (
              <UserChatAnswerLayout
                isLoading={chat?.isLoading}
                hideAvatar={true}
              >
                <UserChatAnswer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <DoneIcon style={{ color: "#3374B9" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#15314E",
                          padding: "15px",
                        }}
                      >
                        Checking Companies Profile
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <DoneIcon style={{ color: "#3374B9" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#15314E",
                          padding: "15px",
                        }}
                      >
                        Searching documents related to ESG Reporting
                      </Typography>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#3374B9" }}
                          size={20}
                        />
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#15314E",
                            padding: "15px",
                          }}
                        >
                          Gathering data on company with ESG context
                        </Typography>
                      </div>
                      {chat?.isLoading && chat?.thinking && (
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#15314E",
                            padding: "15px",
                          }}
                        >
                          {chat?.thinking}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div ref={messagesEndRef} />
                </UserChatAnswer>
              </UserChatAnswerLayout>
            )} */}
              <div ref={messagesEndRef} />
            </div>
          ))}
        </div>

        {chats.length !== 0 &&
          chats.filter((v, i) => v.isLoading !== false).length === 0 && (
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                width: "73vw",
                // justifyContent: "space-evenly",
                alignItems: "center",
                // border:"solid",
                gap: "5px",
              }}
            >
              {/* {followUpQuestions.map((question, i) => (
              <div key={i} onClick={() => handleFollowUpQuestion(question)}>
                <FollowUpQuestion question={question} />
              </div>
            ))} */}
            </div>
          )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
          height: "70px",
          zIndex: 999999,
          // border:"solid red"
        }}
        onMouseLeave={() => setIsVisible(false)}
        onMouseEnter={() => setIsVisible(true)}
      >
        {isVisible && (
          <>
            <FaArrowUp
              style={{ color: "rgb(51, 116, 185)", cursor: "pointer" }}
              size={20}
              onClick={scrollToTop}
            />
            <FaArrowDown
              style={{ color: "rgb(51, 116, 185)", cursor: "pointer" }}
              size={20}
              onClick={scrollToBottom}
            />
          </>
        )}
      </div>
    </>
  );
}

export default EsgGptChatMessageLayout;
